import React from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, get } from "firebase/database";

import { useState, useRef, useEffect } from "react";
import axios from 'axios';
import Papa from 'papaparse';
import $ from 'jquery';
import moment from 'moment/moment';
import Numeral from 'react-numeral';

const firebaseConfig = {
  apiKey: "AIzaSyCn7-DzjbqzKTI659h6Xyy1cooIF0eGgPw",
  authDomain: "tracker-btw.firebaseapp.com",
  databaseURL: "https://tracker-btw-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "tracker-btw",
  storageBucket: "tracker-btw.appspot.com",
  messagingSenderId: "229507443712",
  appId: "1:229507443712:web:fdb72d91f2ed4e27807ce5"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase();

// function writeUserData(rsn, test) {
//   set(ref(database, 'users/' + rsn), {
//     rsn: rsn,
//     test: test
//   });
// }

// // writeUserData('Sani_fsw');



// function writeUserData(data) {
//   var rsn = document.getElementById("rsn").value;
//     set(ref(database, 'users/' + rsn), {
//       rsn: rsn,
//       data: data
//     });
// }

// function getUserData(rsn) {
//   get(ref(database, 'users/' + rsn)).then((snapshot) => {
//     if (snapshot.exists()) {
//       var result = snapshot.val();
//       console.log(result.data.split(/\r?\n/));
//     } else {
//       alert("No data available");
//     }
//   });
// }

// const test = () => {
//   getUserData('Sani_fsw');
// }

var tempSaveslot = [];
var xpGain = [];
var lvlGain = [];
var rankGain = [];
const skills = ["Overall", "Attack", "Defence", "Strength", "Constitution", "Ranged", "Prayer", "Magic", "Cooking", "Woodcutting", "Fletching", "Fishing", "Firemaking", "Crafting", "Smithing", "Mining", "Herblore", "Agility", "Thieving", "Slayer", "Farming", "Runecrafting", "Hunter", "Construction", "Summoning", "Dungeoneering", "Divination", "Invention", "Archaeology"];
var timePassed;


const Tracker = () => {

    const [parsedData, setParsedData] = useState([]);
    const [statRows, setStatRows] = useState([]);
    const [values, setValues] = useState([]);
    var saveDay = false;


    // window.setInterval(function () {
    //   var date = new Date();
    //   if ((date.getMinutes() % 5) == 0) {

    //       refreshStats();
    //   }
    // }, 60000);
    

    $('#rsn').change(function(){ 
      var debounce = false;
      if (debounce === false) {
        debounce = true;
        xpGain = [];
        rankGain = [];
        lvlGain = [];
        refreshStats();
      }
    });


    const refreshStats = async () => {
      trackerWorker();
      // var lastSaveStamp = localStorage.getItem("lastSaveStamp");
      // timePassed = moment(lastSaveStamp).fromNow();
      
      // localStorage.setItem("lastUpdate", new Date(Date.now()));
      // var lastUpdate = localStorage.getItem("lastUpdate");
    }

    const newDay = async () => {
      trackerWorker(saveDay = true);
    }

    useEffect(() => {
      refreshStats();
    }, []);



    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
              arr.push(undefined);
          }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    };



    // Push snapshot onto Firebase
    function pushData(rsn, data) {
      var timestamp = Date.now();
      console.log(timestamp);
      set(ref(database, 'users/' + rsn), {
        rsn: rsn,
        data: data,
        day: timestamp,
      });
    }


    function trackerWorker(saveDay) {
      var rsn = document.getElementById("rsn").value;

      get(ref(database, 'users/' + rsn)).then((snapshot) => {
        if (snapshot.exists()) {
          var result = snapshot.val();
          tempSaveslot = result.data;
          console.log('old', tempSaveslot);

          var oldDaystamp = result.day;
          timePassed = moment(oldDaystamp).fromNow();
        }
      });

      axios.get(`https://api.codetabs.com/v1/proxy?quest=https://secure.runescape.com/m=hiscore_seasonal/index_lite.ws?player=${rsn}`) 
            .then((response) => {
                const data = response.data;

               if(saveDay === true) {
                  // If no data, push a snapshot onto Firebase
                  console.log("Making a snapshot of hiscores...");
                  pushData(rsn, data);
                }

                Papa.parse(tempSaveslot, {
                  header: false,
                  complete: function (results) {
                  const oldRowsArray = [];
                  const oldValuesArray = [];
          
                  // Iterating data to get column name and their values
                  results.data.map((d) => {

                      oldRowsArray.push(Object.keys(d));
                      oldValuesArray.push(Object.values(d));
                  });
                
                  Papa.parse(data, {
                      header: false,
                      skipEmptyLines: true,
                      complete: function (results) {
                      const rowsArray = [];
                      const valuesArray = [];
              
                      // Iterating data to get column name and their values
                      results.data.map((d) => {
                          rowsArray.push(Object.keys(d));
                          valuesArray.push(Object.values(d));
                      });

                      for(var i = 0; i < skills.length; i++) {
                        var oldXP = oldValuesArray[i][2];
                        var oldLvl = oldValuesArray[i][1];
                        var oldRank = oldValuesArray[i][0];
                        var newXP = valuesArray[i][2];
                        var newLvl = valuesArray[i][1];
                        var newRank = valuesArray[i][0];
                        var tempXPGain = newXP - oldXP;
                        var tempLvlGain = newLvl - oldLvl;
                        var tempRankGain = oldRank - newRank;

                        if (oldRank - newRank > 0) {
                          tempRankGain = " (+" + tempRankGain + ")";
                        } else if (oldRank - newRank < 0) {
                          tempRankGain = " (" + tempRankGain + ")";
                        } 

                        if (oldXP === newXP) {
                          tempXPGain = 0;
                          tempRankGain = " ";
                        } else {
                          tempXPGain = tempXPGain;
                        }

                        if (oldLvl === newLvl) {
                          tempLvlGain = "";
                        } else {
                          tempLvlGain = `+` + tempLvlGain;
                        }
                        xpGain.push(tempXPGain);
                        lvlGain.push(tempLvlGain);
                        rankGain.push(tempRankGain);
                        array_move(valuesArray[i], 0, 2);
                      }

                      // Parsed Data Response in array format
                      setParsedData(results.data);
              
                      // Filtered Column Names
                      setStatRows(rowsArray[0]);
              
                      // Filtered Values
                      setValues(valuesArray);

                      if (saveDay === true) {
                        pushData(rsn, data);
                      }
                      },
                  });
                }
              });
            
            });
          }


  return (
    <div className="tracker">
        <table>
          <div className="timers">
            <span>Your day started {timePassed}</span>
            <select id="rsn">
              <option value="Sani_fsw">Sani fsw</option>
              <option value="Noman_fsw">Noman fsw</option>
              <option value="Summitodo">Summitodo</option>
              <option value="Jacky_Fresh">Jacky Fresh</option>
              <option value="Zaddy_Honaw">Zaddy Honaw</option>
              <option value="t0xic_Kah">t0xic Kah</option>
            </select>
            <button onClick={newDay} type="submit">Start new day</button>
          </div>
        <thead>
          <tr>
            <th>Skill</th>
            <th>Level</th>
            <th>XP</th>
            <th>Rank</th>
            <th>Gains</th>
          </tr>
        </thead>
        <tbody>
          {values.slice(0, skills.length).map((value, index) => {
            if (index === 0) {
              var icon = 'https://runescape.wiki/images/Statistics.png?1eb15';
            } else {
              var icon = `https://runescape.wiki/images/${skills[index]}_icon_highscores.png`;
            }
            return (
              <tr key={index}>
                <th><span><img src={icon}/></span>{skills[index]}</th>
                {value.map((val, i) => {
                  if (i === 2) {
                    var localRankGain = rankGain[index];
                    if (localRankGain === 0) {
                      localRankGain = "";
                    }
                  }
                  if(val === '-1') {
                    val = '0';
                  }
                    return <td key={i}><Numeral value={val} format={"0,0"}/><span className="rankgains">{localRankGain}</span></td>;
                })}
                <td className="gains"><Numeral value={xpGain[index]} format={"0,0"}/> <span>{lvlGain[index]}</span></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Tracker;