import './css/style.css';
import Tracker from './components/Tracker';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Tracker btw</h1>
      </header>

      <Tracker/>
    </div>
  );
}

export default App;
